import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    formatKPIValue,
    getActiveRegion,
    getThresholdsFromRegions,
    KPI,
    type Regions,
    toKPIChartDataPoints,
} from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { SoftwareVersionStatusKPIDataSourceRow } from "pages/SoftwareVersionOverviewPage/software-version-overview.types";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";
import { roundNumber } from "utils/number.utils";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo"> & {
    showLink?: boolean;
};

const SoftwareVersionStatusKpiWidget = (props: Props) => {
    const {
        queryParameters: { dateFrom, dateTo },
        showLink,
    } = props;

    let link: string | undefined;
    if (showLink) {
        link = generatePath(routes.softwareVersionOverview);
    }

    const dataSource = useDataSource<SoftwareVersionStatusKPIDataSourceRow[]>("SoftwareVersion_Status_KPI", {
        dateFrom: String(dateFrom),
        dateTo: String(dateTo),
    });

    const dataSourceData = dataSource.data ?? [];
    const { data, lastDataPoint } = toKPIChartDataPoints(dataSourceData, {
        getLabel: (value) => formatKPIValue(roundNumber(value * 100, 2), { suffix: "%" }),
    });

    const regions: Regions = [
        { type: "region", color: "green" },
        { type: "threshold", value: 0.9, regionIfEqual: "lower" },
        { type: "region", color: "yellow" },
        { type: "threshold", value: 0.85, regionIfEqual: "lower" },
        { type: "region", color: "orange" },
        { type: "threshold", value: 0.8, regionIfEqual: "lower" },
        { type: "region", color: "red" },
    ];
    const thresholds = getThresholdsFromRegions({ regions, lastDataPoint });
    const activeRegion = getActiveRegion({ regions, lastDataPoint });
    const color = activeRegion?.color;

    return (
        <>
            <WidgetFrame.Title
                title={"Astronaut Software Update"}
                subtitle={"% of up-to-date and transition SW versions"}
                icon={"lely-icon-robot"}
                size={"sm"}
                link={link}
            />
            <WidgetFrame.KPI dependencies={[dataSource]}>
                <KPI.LastValue title="Last Full Week" value={lastDataPoint?.label} color={color} />
                <KPI.Chart data={data} thresholds={thresholds} color={color} />
            </WidgetFrame.KPI>
        </>
    );
};

export { SoftwareVersionStatusKpiWidget };
