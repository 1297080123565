import type {
    AgreementType,
    InsightsWidgetDataRow,
} from "pages/DashboardPage/tss/widgets/InsightsWidget/insights-widget.types";

export const agreementSelectId = {
    All: "Other",
    TS2: "TS2",
    TS3: "TS3",
    TS4: "TS4",
    Master: "Master",
    Clear: "Clear",
} satisfies Record<string, AgreementType | "Clear">;
type AgreementSelectId = (typeof agreementSelectId)[keyof typeof agreementSelectId];

export const agreementsSelectOptions: { id: string; name: string }[] = [
    {
        id: agreementSelectId.All,
        name: "All",
    },
    {
        id: agreementSelectId.TS2,
        name: "TSS Level 2",
    },
    {
        id: agreementSelectId.TS3,
        name: "TSS Level 3",
    },
    {
        id: agreementSelectId.TS4,
        name: "TSS Level 4",
    },
    {
        id: agreementSelectId.Clear,
        name: "Clear",
    },
] satisfies { id: AgreementSelectId; name: string }[];

export const getAgreementSelectTriggerLabel = (selectedAgreements: string[]): string | null => {
    /**
     * If no agreements are selected, return null (shows placeholder)
     * If "Any" is selected, return "Agreements: Any"
     * If specific agreements show all selected, e.g. "Agreements: TS2, TS3, TS4"
     */

    if (selectedAgreements.length === 0) {
        return null;
    } else if (selectedAgreements.includes(agreementSelectId.All)) {
        return "Agreements: All";
    } else {
        const selectedAgreementsNames = agreementsSelectOptions
            .filter((option) => selectedAgreements.includes(option.id))
            .map((option) => option.name);

        return `Agreements: ${selectedAgreementsNames.join(", ")}`;
    }
};

export const filterBySelectedAgreements = (
    data: InsightsWidgetDataRow[],
    selectedAgreements: string[]
): InsightsWidgetDataRow[] => {
    return data.filter((row) => {
        // agreements come from BE as array string with values separated by comma (e.g. "TS2,TS3")
        const rowAgreementTypes = row.AgreementTypes?.split(",") ?? null;

        /**
         * If no agreements are selected, return all rows
         * If "Any" is selected, return rows that have any agreement type (not null)
         * If specific agreements are selected, return rows that have at least one of the selected agreement types
         */

        if (selectedAgreements.length === 0) {
            return true;
        } else if (!rowAgreementTypes) {
            return false;
        } else if (selectedAgreements.includes(agreementSelectId.All)) {
            return rowAgreementTypes !== null;
        } else {
            return rowAgreementTypes.some((rowAgreementType) =>
                selectedAgreements.some((selectedAgreement) => selectedAgreement === rowAgreementType)
            );
        }
    });
};
